<template>
  <div id="app">
    <component :is="layout"> <router-view :layout.sync="layout" /> </component>
    <van-overlay :show="show">
      <div class="wrapper">
        <img src="../public/tip1.png" class="image" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      layout: `div`,
      show: false,
    };
  },
  created() {

  },
  mounted() {
    window.addEventListener("resize", this.renderResize, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.renderResize, false);
  },
  methods: {
    renderResize() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (width > height) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="less">
//全局 Reset
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  height: 100%;
}

body {
  font-family: -apple-system-font, PingFangSC-Regular, "Helvetica Neue",
  Helvetica, sans-serif;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.85);
  height: 100%;
}

#app {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
li {
  list-style: none;
}
.page-container {
  background-color: white;
}
.page-content {
  min-height: 66vh;
  padding: 1rem 1.6rem;
}
.page-content__grey {
  background-color: #efefef;
}
.page-content__nopadding {
  padding: 0rem;
}
.page-content__grey {
  background-color: #efefef;
}
input::-webkit-input-placeholder {
  color: #a3a3a3;
}
.score {
  width: 5rem;
  text-align: center;
  font-size: 1.3rem;
  border: 1px solid #fb6349;
  border-radius: 2px;
  color: #fb6349;
}
.filler {
  flex-grow: 1;
}
.card {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #a7a7a7;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.image {
  width: 100%;
}
.switch {
  width: 4rem;
}
</style>
